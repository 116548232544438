<template>
  <v-container fluid>
    <report-title :title="options.title" class="mb-2" />
    <report-filter-log @change-filter="fetchReport($event)" @export-table="exportTableToXLSX()" />
    <report-table ref="reportTable" :headers="options.headers" :items="options.data" />
    <report-alert />
    
  </v-container>
</template>

<script >
import { Vue } from "vue-property-decorator";
import { get } from "vuex-pathify";
import { mapActions } from "vuex";
import { json2xlsx } from "@/util/helpers";

export default Vue.extend({
  name: "ReportUsersActivity",
  components: {
    ReportTitle: () => import("@/components/report/ReportTitle.vue"),
    ReportFilterLog: () => import("@/components/report/ReportFilterLog.vue"),
    ReportTable: () => import("@/components/report/ReportTable.vue"),
    ReportAlert: () => import("@/components/report/ReportAlert.vue"),
  },
  computed: {
    options: get("report/optionsUsersActivity"),
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions({
      resetReport: "report/resetUsersActivityReport",
      fetchReport: "report/fetchUsersActivityData",
    }),
    exportTableToXLSX() {
      var currentTab = this.$refs.reportTable;
      this.options.data.length > 0
        ? json2xlsx({
            data: this.options.data,
            header: [this.options.headers.map(header => header.text)],
            sheetName: "Отчет",
            fileName:
              "ИСУН - " + this.options.title + ".xlsx"
          })
        : console.log(this.errorMessage);
    }
  },
  mounted() {
    this.resetReport();
  },
});
</script>